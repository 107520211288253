<template>
  <div class="agentManage">
    <div class="searchForm_box">
      <el-form
        :inline="true"
        size="small"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="代理商名稱">
          <el-input
            v-model="searchForm.agent_name"
            placeholder="請輸入代理商名稱"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getAgentList">搜索</el-button>
          <el-button type="success" @click="addAgent">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="agentList" border class="table" v-loading="tableLoading">
      <el-table-column label="代理商ID" prop="agent_id"></el-table-column>
      <el-table-column label="代理商名稱" prop="agent_name"></el-table-column>
      <el-table-column label="真實姓名" prop="realname"></el-table-column>
      <el-table-column
        label="電話"
        prop="phone"
        align="center"
      ></el-table-column>
      <!--            <el-table-column label="用戶類型" align="center">-->
      <!--                <template slot-scope="scope">-->
      <!--                    {{ scope.row.user_type == 1 ? "代理商" : "普通員工" }}-->
      <!--                </template>-->
      <!--            </el-table-column>-->
      <el-table-column
        label="詳細地址"
        prop="address"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="帳號狀態" align="center">
        <template slot-scope="scope">
          {{
            scope.row.status == 1
              ? "正常"
              : scope.row.status == 2
              ? "停用"
              : scope.row.status == 3
              ? "待審核"
              : "審核失敗"
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="平台費率" prop="platform_rate"></el-table-column> -->
      <!--            <el-table-column label="上級費率" prop="superior_rate"></el-table-column>-->
      <!--            <el-table-column label="景區費率" prop="area_rate"></el-table-column>-->
      <!--            <el-table-column label="其他費率" prop="other_rate"></el-table-column>-->
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="is_tree == 1"
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="addAgent(scope.row)"
            >新增
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="edit(scope.row.agent_id)"
            >編輯
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="deleteAgent(scope.row.agent_id)"
            >刪除
          </el-button>
          <el-button
            v-if="scope.row.status == 3"
            size="mini"
            type="text"
            icon="el-icon-collection-tag"
            @click="examineAgent(scope.row.agent_id)"
            >審核
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-collection-tag"
            @click="initCategory(scope.row)"
            >初始分類
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分頁 -->
    <el-pagination
      v-if="is_tree == 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 添加 -->
    <el-dialog
      :title="agentTitle + '商戶'"
      :visible.sync="agentVisible"
      width="700px"
    >
      <el-form
        :model="agentForm"
        :rules="agentFormRules"
        ref="agentForm"
        label-width="100px"
        class="demo-ruleForm"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="代理商角色">
              <el-select v-model="agentForm.role_id">
                <el-option
                  v-for="item in roleOption"
                  :key="item.role_id"
                  :label="item.role_name"
                  :value="item.role_id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="代理商名稱" prop="agent_name">
              <el-input
                v-model="agentForm.agent_name"
                placeholder="請輸入代理商名稱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真實姓名" prop="realname">
              <el-input
                v-model="agentForm.realname"
                placeholder="請輸入真實姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手機號" prop="phone">
              <el-input
                v-model="agentForm.phone"
                placeholder="請輸入手機號"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="密碼" prop="password">
              <el-input
                v-model="agentForm.password"
                show-password
                :placeholder="
                  agentTitle == '添加' ? '請輸入密碼' : '為空則表示不修改密碼'
                "
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用戶類型" prop="user_type">
              <el-radio-group v-model="agentForm.user_type">
                <el-radio
                  v-for="item in userTypeList"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性別" prop="sex">
              <el-radio-group v-model="agentForm.sex">
                <el-radio
                  v-for="item in sexList"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="店鋪地址" prop="address">
              <el-input
                v-model="agentForm.address"
                placeholder="請輸入店鋪地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="賬號狀態">
              <el-radio-group v-model="agentForm.status">
                <el-radio
                  v-for="(item, index) in statusList"
                  :key="index"
                  :label="item.value"
                  >{{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
                        <el-form-item label="平台費率" prop="platform_rate">
                            <el-input
                                    v-model="agentForm.platform_rate"
                                    placeholder="請輸入平台費率"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="上級費率" prop="superior_rate">
                            <el-input
                                    v-model="agentForm.superior_rate"
                                    placeholder="請輸入上級費率"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="景區費率" prop="area_rate">
                            <el-input
                                    v-model="agentForm.area_rate"
                                    placeholder="請輸入景區費率"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="其他費率" prop="other_rate">
                            <el-input
                                    v-model="agentForm.other_rate"
                                    placeholder="請輸入其他費率"
                            ></el-input>
                        </el-form-item>
                    </el-col> -->
          <el-col :span="24">
            <el-form-item label="備註" prop="remark">
              <el-input
                v-model="agentForm.remark"
                placeholder="請填寫備註"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="agentVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="agentConfirm"
          >確 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 審核 -->
    <el-dialog :visible.sync="examineVisible" title="審核" width="400px">
      <el-form>
        <el-form-item label="審核結果">
          <el-radio-group v-model="examineStatus">
            <el-radio :label="1">審核通過</el-radio>
            <el-radio :label="4">審核拒絕</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="拒絕原因" v-if="examineStatus == 4">
          <el-input
            v-model="examineReason"
            placeholder="請輸入拒絕原因"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examineVisible = false">取 消</el-button>
        <el-button type="primary" @click="examineConfirm">確 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { initAgentCate } from "@/api/barcode";
export default {
  name: "agentManage",
  data() {
    return {
      // 表格loading
      tableLoading: false,
      searchForm: {
        agent_name: "",
      },
      page: 1,
      pageSize: 50,
      total: 0,
      // 是否是樹狀
      is_tree: 0,
      agentList: [],
      // 添加、修改
      roleOption: [],
      agentVisible: false,
      agentTitle: "添加",
      agentForm: {},
      userTypeList: [
        { label: "代理商", value: 1 },
        { label: "普通員工", value: 2 },
      ],
      sexList: [
        { label: "男", value: 1 },
        { label: "女", value: 2 },
      ],
      statusList: [
        { label: "正常", value: 1 },
        { label: "停用", value: 2 },
        { label: "待審核", value: 3 },
      ],
      agentFormRules: {
        agent_name: [
          { required: true, message: "請輸入代理商名稱", trigger: "blur" },
        ],
        password: [{ required: true, message: "請輸入密碼", trigger: "blur" }],
        phone: [
          { required: true, message: "請輸入手機號", trigger: "blur" },
          { min: 11, max: 11, message: "請輸入正確的手機號", trigger: "blur" },
        ],
      },
      // 審核
      examineVisible: false,
      agent_id: "",
      examineStatus: 1,
      examineReason: "",
    };
  },
  created() {
    this.getAgentList();
  },
  mounted() {
    this.getRoleOption();
  },
  methods: {
    getAgentList() {
      this.tableLoading = true;
      this.$api
        .agentList({
          page: this.page,
          pageSize: this.pageSize,
          agent_name: this.searchForm.agent_name,
          is_tree: this.is_tree,
        })
        .then((res) => {
          this.tableLoading = false;
          if (res.code == 200) {
            this.agentList = res.data.list;
            this.total = res.data.count;
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
    // 分頁
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAgentList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getAgentList();
    },
    // 重置
    agentReset() {
      this.agentForm = {
        role_id: "",
        agent_name: "",
        realname: "",
        user_type: 1,
        phone: "",
        sex: 1,
        address: "",
        password: "",
        status: 1,
        platform_rate: "",
        superior_rate: "",
        area_rate: "",
        other_rate: "",
        remark: "",
      };
      this.$nextTick(() => {
        this.$refs["agentForm"].resetFields();
      });
    },
    // 獲取代理商角色
    getRoleOption() {
      this.$api
        .getRole({
          page: 1,
          pageSize: 99999,
        })
        .then((res) => {
          this.roleOption = res.data.list;
        });
    },
    // 添加
    addAgent(row) {
      this.agentFormRules = {
        agent_name: [
          { required: true, message: "請輸入代理商名稱", trigger: "blur" },
        ],
        password: [{ required: true, message: "請輸入密碼", trigger: "blur" }],
        phone: [
          { required: true, message: "請輸入手機號", trigger: "blur" },
          { min: 11, max: 11, message: "請輸入正確的手機號", trigger: "blur" },
        ],
      };
      this.agentReset();
      this.agentTitle = "添加";
      this.agentVisible = true;
    },
    // 編輯
    edit(id) {
      this.agentFormRules = {
        agent_name: [
          { required: true, message: "請輸入代理商名稱", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "請輸入手機號", trigger: "blur" },
          { min: 11, max: 11, message: "請輸入正確的手機號", trigger: "blur" },
        ],
      };
      this.agentReset();
      this.agentTitle = "編輯";
      this.agentVisible = true;
      this.$api.agentDetail({ agent_id: id }).then((res) => {
        if (res.code == 200) {
          this.agentForm = res.data;
          this.agentForm.password = "";
          this.agentVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 提交
    agentConfirm() {
      this.$refs["agentForm"].validate((valid) => {
        if (valid) {
          // const loading = this.$loading({
          //     text: "正在提交...",
          //     background: "rgba(255, 255, 255, 0.5)",
          // });
          if (
            this.agentForm.agent_id != null &&
            this.agentForm.agent_id != undefined &&
            this.agentForm.agent_id != ""
          ) {
            var params = {
              role_id: this.agentForm.role_id,
              agent_id: this.agentForm.agent_id,
              parent_id: this.agentForm.parent_id,
              agent_name: this.agentForm.agent_name,
              realname: this.agentForm.realname,
              user_type: this.agentForm.user_type,
              phone: this.agentForm.phone,
              sex: this.agentForm.sex,
              address: this.agentForm.address,
              password: this.agentForm.password,
              status: this.agentForm.status,
              platform_rate: this.agentForm.platform_rate,
              superior_rate: this.agentForm.superior_rate,
              area_rate: this.agentForm.area_rate,
              other_rate: this.agentForm.other_rate,
              remark: this.agentForm.remark,
            };
            this.$api.editAgent(params).then((res) => {
              // loading.close();
              this.agentVisible = false;
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.getAgentList();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            this.$api.addAgent(this.agentForm).then((res) => {
              // loading.close();
              this.agentVisible = false;
              if (res.code == 200) {
                this.$message.success("添加成功");
                this.getAgentList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
    // 刪除
    deleteAgent(id) {
      this.$confirm("此操作將永久刪除該代理商用戶, 是否繼續?", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // const loading = this.$loading({
          //     text: "正在刪除...",
          //     background: "rgba(255,255,255,0.5)",
          // });
          this.$api.delAgent({ agent_id: id }).then((res) => {
            // loading.close();
            if (res.code == 200) {
              this.$message.success("刪除成功");
              this.getAgentList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 審核
    examineAgent(id) {
      this.agent_id = id;
      this.examineVisible = true;
      this.examineStatus = 1;
      this.examineReason = "";
    },
    examineConfirm() {
      if (this.examineStatus == 4 && this.examineReason == "") {
        this.$message.warning("請填寫拒絕原因");
      } else {
        const loading = this.$loading({
          text: "正在提交審核...",
          background: "rgba(255,255,255,0.5)",
        });
        this.$api
          .examineAgent({
            agent_id: this.agent_id,
            status: this.examineStatus,
            msg: this.examineReason,
          })
          .then((res) => {
            loading.close();
            if (res.code == 200) {
              this.$message.success("審核提交成功");
              this.examineVisible = false;
              this.getAgentList();
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
    initCategory(row) {
      this.$modal
        .confirm('是否確認初始化代理商"' + row.agent_name + '"的商品分類？')
        .then(function () {
          return initAgentCate({ agent_id: row.agent_id });
        })
        .then(() => {
          this.$modal.msgSuccess("初始化成功");
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/agentManage.scss";
</style>
