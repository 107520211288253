import http from "../utils/request"
import request from "../utils/request";


//查询条码列表
export function listBarcode(data) {
    return http({
        url: '/admin/barcode/list',
        method: 'post',
        data: data
    })
}

// 新增条码
export function addBarcode(data) {
    // return http(
    //     '/admin/barcode/add',
    //     data,
    //     'post',
    // )
    return http({
        url: '/admin/barcode/add',
        method: 'post',
        data: data
    })
}

// 修改条码
export function editBarcode(data) {
    // return http(
    //     '/admin/barcode/edit',
    //     data,
    //     'post',
    // )
    return http({
        url: '/admin/barcode/edit',
        method: 'post',
        data: data
    })
}

// 删除条码
export function delBarcode(data) {
    // return http(
    //     '/admin/barcode/delete',
    //     data,
    //     'post',
    // )
    return http({
        url: '/admin/barcode/delete',
        method: 'post',
        data: data
    })
}
export function uploadPic(data) {
    return http({
        url: '/api/pic',
        method: 'post',
        data: data
    })
}

//初始化代理商分类
export function initAgentCate(data) {
    return  http(
        {
            url: "/admin/agent/init",
            data: data,
            method: 'post'
        }
    )
}